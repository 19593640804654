import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Chatbot script example`}</h3>
    <h4>{`Chatbot Phone Shopping`}</h4>
    <p>{`We are going to create a chtabot where users can see the mobile phones and their features available in our store.`}</p>
    <p>{`We will use a json file with some phones and their features as an example. For this use case will need to code to manage the json file on `}<em parentName="p">{`Configuration`}</em>{` > `}<em parentName="p">{`Advanced configurations`}</em>{`.`}</p>
    <p><strong parentName="p">{`Use Case`}</strong></p>
    <ol>
      <li parentName="ol">{`Welcome customer to our CoreMedia phone shop.`}</li>
      <li parentName="ol">{`Ask for name and age.`}</li>
      <li parentName="ol">{`Ask to choose the brand.`}</li>
      <li parentName="ol">{`Show the phones available for the brand selected in a card.`}</li>
      <li parentName="ol">{`Request to choose a mobile phone.`}</li>
      <li parentName="ol">{`Show the phone features.`}</li>
      <li parentName="ol">{`Try to connect to Live Chat.`}</li>
      <li parentName="ol">{`Closing statement.`}</li>
    </ol>
    <p><strong parentName="p">{`Script`}</strong></p>
    <p>{`On `}<em parentName="p">{`Script`}</em>{` tab:`}</p>
    <ol>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add Action`}</em>{`.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Text or HTML`}</em>{` on `}<em parentName="li">{`Write`}</em>{`.`}</li>
      <li parentName="ol">{`Add the text to be shown on `}<em parentName="li">{`Configuration`}</em>{` tab > `}<em parentName="li">{`text/HTML`}</em>{` ("Hello,
Welcome to our online CoreMedia phone shop.)"`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Close`}</em>{`.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Text`}</em>{` on `}<em parentName="li">{`Ask`}</em>{`. `}</li>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`Question`}</em>{` and variable name ("What's your name?" and my_name).`}</li>
      <li parentName="ol">{`Repeat 5. and 6. steps and ask about customer age.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Ask: Options from URL`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`Question`}</em>{` "Choose your favourite brand." and select the `}<em parentName="li">{`Display as`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`List of Answers`}</em>{` filling in `}<em parentName="li">{`URL`}</em>{` and define `}<em parentName="li">{`Variable value`}</em>{` and `}<em parentName="li">{`Text`}</em>{`. Define the `}<em parentName="li">{`Variable Name`}</em>{`.`}</li>
      <li parentName="ol">{`On `}<em parentName="li">{`Advanced`}</em>{` tab fill in `}<em parentName="li">{`Data Pre-processor function Name`}</em>{` with the function coded on `}<em parentName="li">{`Advanced configurations`}</em>{` to show categiories.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fb071a0446b905b6b7cba185e3d89b81/4bab5/getCategories.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "31%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA30lEQVQY042R64rEIAyF+/7vuEynUC8ZB229tEXtGZKly9BfGwgmJvk86lAK4NwJogylIoxZYd4eKSfUXtF6Q60V53ni2zi//MrZBg5673i9Apx7Q6kZZAl+9XDRIcQAIhJoa038G3IHD1eyLB3zbGCMwTRN0ErjOT4FbokwjqPUrLXQWsN7L/C76j+FOR/IuWDbNuz7Dh+8DOWcEWNESgnHcUgcQpBaKUX2eY9nRCHwq5AI0Drg8fiRpnVdsSyLxLzyQQzknJ0PYiDDuJfrAuS3YYWsvrUuQ/cP+K/xlT9RDNIxF9nTWQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/fb071a0446b905b6b7cba185e3d89b81/e93cc/getCategories.webp 300w", "/static/fb071a0446b905b6b7cba185e3d89b81/b0544/getCategories.webp 600w", "/static/fb071a0446b905b6b7cba185e3d89b81/68fc1/getCategories.webp 1200w", "/static/fb071a0446b905b6b7cba185e3d89b81/b3f17/getCategories.webp 1448w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/fb071a0446b905b6b7cba185e3d89b81/eed55/getCategories.png 300w", "/static/fb071a0446b905b6b7cba185e3d89b81/7491f/getCategories.png 600w", "/static/fb071a0446b905b6b7cba185e3d89b81/8537d/getCategories.png 1200w", "/static/fb071a0446b905b6b7cba185e3d89b81/4bab5/getCategories.png 1448w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/fb071a0446b905b6b7cba185e3d89b81/8537d/getCategories.png",
                "alt": "getCategories",
                "title": "getCategories",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Ask: Card list from URL`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`Question`}</em>{` (From brand {{%category%}} choose your mobile phone), `}<em parentName="li">{`URL`}</em>{`, `}<em parentName="li">{`Parameter`}</em>{` and `}<em parentName="li">{`Value`}</em>{`. Define the `}<em parentName="li">{`Variable Name`}</em>{` that will be used on the functions.`}</li>
      <li parentName="ol">{`On `}<em parentName="li">{`Advanced`}</em>{` tab fill in `}<em parentName="li">{`Data Pre-processor function Name`}</em>{` with the function coded on `}<em parentName="li">{`Advanced configurations`}</em>{` to show brands by categories.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Advanced: Variables from URL`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`Service Format`}</em>{`, `}<em parentName="li">{`Service URL`}</em>{`, `}<em parentName="li">{`Field in the source service`}</em>{` and `}<em parentName="li">{`Variable Name`}</em>{`.`}</li>
      <li parentName="ol">{`On `}<em parentName="li">{`Advanced`}</em>{` tab fill in `}<em parentName="li">{`Data Pre-processor function Name`}</em>{` with the function coded on `}<em parentName="li">{`Advanced configurations`}</em>{` to get Brand Details.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Write: Text or HTML`}</em>{` to show the phone details and fill in `}<em parentName="li">{`text/HTML`}</em>{`.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/4ec8e/write-text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABvklEQVQ4y6WSa2vbMBSG/e8H+9b/MVg3WJeWXRr2aQxGIcvImjaO40uc2LEt27LdxNYzpCYtDaPJ6IEHSebVey6y9f6sR+/LJadnPd5++GhWzfnXPhdHorWf+t94c/oO64/tMF+ljB2X37dTRrbD8MZmtlzhRskRrIzWTwQ/B0OsRbTCSSqCrKZad+T1Bh1KKVTXodRzKC2k6zpzx3EcrDBO8LIGL62o1i3ybkO7FRwKrWvbjvXmvojpdIq1WITIQqC61mTaZdb7Q4TRCnceEiyWLJOU6/EN1jwMcWYzRte3XA1GTGc+UlZHGe5o29ZUOLFtrDiOqaqKLBNEcWrM1JEt78LMErC1YZIkvDT2DNPHV9XAf7X7pOXJBCuKIqSU5HlOKSVlWRrBswZ7583WcPvKC2OiTfUsNUaoNOpJBYfC/IdBEBjDsigMsigo8pxC5ORCmLXQ36V8YHc298qSTIhHQ9fziKMI4YcId47w5uReSO6HZI6PcAPqUlI3DU3TUNe16ULvq6qmaWro1sbQd2dYOsNLYq3g5HLCq88Tvv8aY3me9/AomuJf6DHskef3I4pTwcnFgNfnQ/o/rvgLbf96+XTuBucAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3e35d7fa68b2e2ed1fe001780adfd4e1/e93cc/write-text.webp 300w", "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/b0544/write-text.webp 600w", "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/68fc1/write-text.webp 1200w", "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/c7b4c/write-text.webp 1508w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3e35d7fa68b2e2ed1fe001780adfd4e1/eed55/write-text.png 300w", "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/7491f/write-text.png 600w", "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/8537d/write-text.png 1200w", "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/4ec8e/write-text.png 1508w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/3e35d7fa68b2e2ed1fe001780adfd4e1/8537d/write-text.png",
                "alt": "write-text",
                "title": "write-text",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Live Chat: CoreMedia`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`CoreMedia Autenthication Profile`}</em>{`, `}<em parentName="li">{`Skill`}</em></li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Write: Text or HTML`}</em>{` and fill in `}<em parentName="li">{`text/HTML`}</em>{`.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4ef76983657cfb1cd9037b1677530d49/70f1e/script.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "62.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABJklEQVQoz52SXWvCMBSG8///1m4G28UGipsOXVtbtdVG83WaNM07kqJ42e7AIRDIw3PyHnYVEuvfHJusSL0tSng/IIQwucca37CbVAlyud7G62EuLOAiOmS1hCQHRtaiaTk0UQLOgd3tikbjY8vBlQWTWiM/nGC67gGcCo7lh4CvvcDnjqOVFkwbQlk3qJozOmtnW0Zg1RpktRpHVsagqhsc/gGMRdbj9fuMl2WNIyewmPJPvseurLDbVynx+KcpoMmWA1zfp5NZ6yCkQkxbqHhKSKVBRHDOTQIOz8D60uJ9ucLbYoUzv84yewBDQO99AjOhNNZZnkblQo4jzFzsu2VabEOEpm2TnaEOtu+T/lyYdQ7ee7ATF9jkJYrj6ZHy1Hre2bvhH4ZZq0U1LhaeAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4ef76983657cfb1cd9037b1677530d49/e93cc/script.webp 300w", "/static/4ef76983657cfb1cd9037b1677530d49/b0544/script.webp 600w", "/static/4ef76983657cfb1cd9037b1677530d49/68fc1/script.webp 1200w", "/static/4ef76983657cfb1cd9037b1677530d49/a2303/script.webp 1800w", "/static/4ef76983657cfb1cd9037b1677530d49/a0d3f/script.webp 2292w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4ef76983657cfb1cd9037b1677530d49/eed55/script.png 300w", "/static/4ef76983657cfb1cd9037b1677530d49/7491f/script.png 600w", "/static/4ef76983657cfb1cd9037b1677530d49/8537d/script.png 1200w", "/static/4ef76983657cfb1cd9037b1677530d49/d2cc9/script.png 1800w", "/static/4ef76983657cfb1cd9037b1677530d49/70f1e/script.png 2292w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4ef76983657cfb1cd9037b1677530d49/8537d/script.png",
                "alt": "script",
                "title": "script",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/724a17f3972b1982338ab7205f189858/65fde/body-block-a.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB1ElEQVQ4y42UiW7bMAyG/f5Pt2EF0qFI1tqJL92WrfjMN8hpXaNbixL4QUoQr5+0E6kMTdtzuy3s5Xa7sSwLy3LX8zwzR/1mz8v7eYfEGINrFrSekDIgRIs0DT50zMvMOE1M07Ql+TfhHfEckVhr1wfWDhwOGQ8Pj/z4+YvHp98cTgdeshdOpxOXywWlNUIIos9bko+SOOdWo+9nlBqoqgJrDVJK8kuOUu9BtDarXVUV0S8idni9XreqE6UU83wj3mkNeV5hbbtytLU03/mdXtsfx3HVwzDQ9/3K6RbQGE0IkKY9x+OZLDM8PRU8P2fUdY12GtkKrn3gK3njN9FavXLYI0RJTKCUpBY1RV4glKAwAu0s0zhu5H/EVmEMGFejLEfOWc7xeKSuBVIq0vQFZx3OgjUj8e1+4vtA21CkFEzTTJoO5EVFWRZordd242TjgKzzNE3soiGE7uuA0TmKD4E/9TNlWXLOzmRpRuvb3XLfsQ/ySYXyPsFlwocG1SiMN1SmWu243HunzzjcFjvuUZRYCQuEMdBeWxrfoLTaVuK7khRFjnMdUhp8FzkKa6vee4Z+2HYt6j3+dxeRKClxbqDxA83V4VuPkmr9Irque/8xfBN/ARnoPtEC+AuYAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/724a17f3972b1982338ab7205f189858/e93cc/body-block-a.webp 300w", "/static/724a17f3972b1982338ab7205f189858/b0544/body-block-a.webp 600w", "/static/724a17f3972b1982338ab7205f189858/68fc1/body-block-a.webp 1200w", "/static/724a17f3972b1982338ab7205f189858/84634/body-block-a.webp 1680w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/724a17f3972b1982338ab7205f189858/eed55/body-block-a.png 300w", "/static/724a17f3972b1982338ab7205f189858/7491f/body-block-a.png 600w", "/static/724a17f3972b1982338ab7205f189858/8537d/body-block-a.png 1200w", "/static/724a17f3972b1982338ab7205f189858/65fde/body-block-a.png 1680w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/724a17f3972b1982338ab7205f189858/8537d/body-block-a.png",
                "alt": "body-block-a",
                "title": "body-block-a",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>
        <span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1186px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d820cf1e6549fbd6430d990d883bfd59/0266f/bodyblockb.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "114.00000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAABYlAAAWJQFJUiTwAAADJUlEQVQ4y62UaXPbNhCG/f//WDvtB7tOFDkOzUviDYICCR7i9XQAybIUOemXLmcHQ4KzeI/FPvi+TxxnRBF82zT8+ccXHh89Hh9dnp6+8fKyxfN83t7eiPKIUIY4mUMgAg7NgXVdsc96yocgCJFSsK4gq5a8SJCVQNUVeZGR5TlxHLPb74mzBKEFcRUja8k8z7bIdTz4fkCe5ywL5FIS5zGiklRNTZhEhMmeUlXoY0977Pk53pEt63JGGIaUQtjNsjzy9FTz40dKIUr82MfZO6RlitQlmcoQjSBVqV2bvrFrXufIRjKN0wlhWUpgZjwuNA0Mw2zO5j6Wc36gM8je0VmErudRFDnzDEWlSMSeKE/Iq5JYZMR5SiJylG5pW9B6pa41Wus7/ayGnu8jRME4QZK0bLeCzeYVP/B5+f4dx3GIk5Tt9hXHKVAKiqKkLEuWZbnR0SL0g8BuzvNIP3Qf5K7omJjniXk5/kIKLmhtQUPZHJYKSZSFRGlsqaZlQaVrVNvQ9C0Hu54ONeiu9Xsval0WZ5eL4sjffx34+jVkt4+IRULRFDaNw6UukVpal80347DSinmaPwqeTCmse+O40HXG5emD+rLcaHSdP9O1Ba0pRYE5JC0r67JQkkQWDOOR38VnRc99mNP34PsdjpORpPnZSUWWHWwX6K6lH3uGcbBXbpqmG4cvCM1wyLLMftDaFK3YbjdEUUyWFQTBjjRNqeqKsj1pOBwHura7o3tBeNIQpnFGKYXruragkMru5Vl2Ef6/qFtTjMvrujBN95qdh9OpRa5a5dSn6z3CN9clz1KMJFFW4e58vF2IE7i2Dwsl0X1r+1APHbJRtjdNT8rmwDTP95SN6OYCVLXmi/eMG5rh+sTzP8+EQUgYhniuS9M0n97fG8qmbcw8NHGce16zV2IVWxk2mw1BENBobd+NnvMVovUzyrvdDmnv8kLfj4zTaF0cx5FhGC4FfoXobtqY0W7u8jCsGKBS1sRxhDnIjP6u605zb1kuJvwuH4w+75T/j3gIdnu8MMLblbih4FA3qEZT1Q3lQdnVvCut7V51zvd/bvYbzb/WnurbwA+XkwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d820cf1e6549fbd6430d990d883bfd59/e93cc/bodyblockb.webp 300w", "/static/d820cf1e6549fbd6430d990d883bfd59/b0544/bodyblockb.webp 600w", "/static/d820cf1e6549fbd6430d990d883bfd59/14c32/bodyblockb.webp 1186w"],
                "sizes": "(max-width: 1186px) 100vw, 1186px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d820cf1e6549fbd6430d990d883bfd59/eed55/bodyblockb.png 300w", "/static/d820cf1e6549fbd6430d990d883bfd59/7491f/bodyblockb.png 600w", "/static/d820cf1e6549fbd6430d990d883bfd59/0266f/bodyblockb.png 1186w"],
                "sizes": "(max-width: 1186px) 100vw, 1186px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d820cf1e6549fbd6430d990d883bfd59/0266f/bodyblockb.png",
                "alt": "body-block-b",
                "title": "body-block-b",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Publish and Save`}</em>{`.`}</li>
    </ol>
    <h3>{`Result`}</h3>
    <p>{`Adding the chatbot to the `}<em parentName="p">{`Chatbot flow`}</em>{` setting on skill, the chatbot is displayed.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "756px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7d44cb72398e36ba284ea36a0526157c/46262/chatbot1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "168.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAiCAYAAABfqvm9AAAACXBIWXMAABYlAAAWJQFJUiTwAAAE90lEQVRIx31VS28bVRT2D2JToOIpsakoSCBRaJs0JbQIiSRFrUghbQXtpoEWlJZ2QUWI0lABQoJNqZAq2CDYsOCVNA/bY8dN/B57XrbncWc8E3/oHHsGO3Zs6dP1zJz73e/c891zYy0hkFeyuJX+FjdTd3Bdus241oU5aRHXpEV8llrCjdRXfbieuo35ze9QUvOIwWpiLrmIx1fHcCgxjUPJaTy7dhIHN6ZwYP1tHNiYwIvxU/z8xOrr2LcygkdXRrFvZRSPPTiG/Q+O48nVcexfPY7PU18jhoaLixs3cUSawWT6Ms5m5jCSOIeT0iVMpC9jKj2LyfQs3pQu4VjyPF6Ln8WRxPs4mpjBK/F3cWBtAi+sT+GZtRO4kvgCMdTbhM+tv4Wx5AVW+fz6JF5NTGMkeQ5j0gUeX4qfxmjyPI4mZzCaPIeR5Axejp/B/pXjeOrBOB5ZOYyriXkidDAbv4Vx6UNcyNzE9OYc3kldwSnpY0xJH2FSmuXxVKr9zEi1QXFn0p/wnBOpi/g0sYCYbehYLW3Ad32gBcBtAR6AHQBBZwz/h88+2jEEsQMIwBMe1opxxBq6ipqqQ7gCd+/dRb1Rh9v0UKpWoRoGZEVhaLVaG4YBy3FAPz/wYdkWTNuEIwQMRUXM1FWoqgLTNHH//n1omgY/CJDNF5ErlpEvlrG5lUP64Tay+RKyhRJKcpVjWq0WHMeBbdsQQkCpVhAzDQ2qUoVwXezs7PAH1/WQK5QgV1XUGyaMWh2aUYOi6TzqtTqazWYfodpDKASrpADP81BRNCYb9iMBQwkty+KPrhBwHNFWV2+wQtO04LouxwnhRv8pfk/CIAh4RYJl2UxYb/xP6LlupCjEngopzeXlZQ6g/anXGzAti6tHsB2Bhmny/obKuskjQrKNpqo8eWlpCaqqwvcDFMsVlCtVKKqOSlVFVdH4HRFSut0qBypkJZ0VaQJPckiZA9/3GUHgcyaO7UQKGbbD+9q3h2EABetGDapuQNMNLlaIUJHneJGpabSFDbVa7SXs3uh2NUXPQlws20JLBPip8htOp6/ifOYGQ7aUzknZg3B3JUOQIrjAjdw3OBifwnjqAxxOvIeKpfYTdqc0DJ7jYrOWxQ/5X/B9/mf8XvlrsA9p40Mlg9KNlDs2hCXg1lx4dQ++3YQr3N6zTNVLp9ORD8nYVGH2Xafq7VFEpFYI29rtQ4XTXVhYiHxIzSFfKnODoO6S2c5xU/CbzYH73JOypigcoOs6NwhSSypdr62OjiQ9+34zIgpHtszgs9xOJwwk/1G7UjWjr1jRAXAEfNGEcOh4Ov1F6fXh8MI0HQ+FRhmzW1/i6vbiYNvs5cPud6G54e5g2Yjj6bU3cCg+PdjYlFq3it3Kom9kG1tArim4k7uHH8u/ci/oO3pUjGEqu79RK9O0GnsxsHy2VZ8Pi8ViROxydf2o03hcZb9TbZ8rT+OePqSV5+fn+dZr+j7yRRnb+SIKJZlvOuqFND7M5mHZzrA7RWUf0ktSSD4k7+U6JEHnuhwEImxvg8V3kNJt7DBVp9NQKUVKjyYNu/VoDl+9nseZxuy6Ed3LtAp9bLV6J9YaJl/6xbLMTiCSTCaDXC4HRVUhyxXoVR3lYgGxpmNBq8qoyGXI5RLkUpGl0yIEWnV7awt///MvpKSEUiGPSrmMdEpi5LNZpB+m8EfmT1TkAv4DrxW48P2hxIYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7d44cb72398e36ba284ea36a0526157c/e93cc/chatbot1.webp 300w", "/static/7d44cb72398e36ba284ea36a0526157c/b0544/chatbot1.webp 600w", "/static/7d44cb72398e36ba284ea36a0526157c/b7d42/chatbot1.webp 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7d44cb72398e36ba284ea36a0526157c/eed55/chatbot1.png 300w", "/static/7d44cb72398e36ba284ea36a0526157c/7491f/chatbot1.png 600w", "/static/7d44cb72398e36ba284ea36a0526157c/46262/chatbot1.png 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7d44cb72398e36ba284ea36a0526157c/46262/chatbot1.png",
              "alt": "chatbot1",
              "title": "chatbot1",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      